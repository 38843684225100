import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, Button, Alert, Spinner } from 'react-bootstrap';
import './Login.css'; // Import custom styles

const Login = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // State to track loading status

  // Use environment variable for API URL
  const API_URL = process.env.REACT_APP_API_URL;
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true when login is in process

    try {
      const response = await axios.post(`${API_URL}/login`, { username, password }, { withCredentials: true });
      const user = response.data.user;
      if (response.status === 200) {
        setError('');
        setLoading(false); // Set loading to false after success
        onLoginSuccess(user); // Trigger login success callback
      }
    } catch (err) {
      setError('Invalid username or password');
      setLoading(false); // Set loading to false after error
    }
  };

  return (
    <Container className="mt-5" style={{ maxWidth: '400px', position: 'relative' }}>
      <h2 className="text-center mb-4">Login</h2>
      <Form onSubmit={handleSubmit} className={`login-form ${loading ? 'loading' : ''}`}>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form.Group controlId="username" className="mb-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            disabled={loading} // Disable input when loading
          />
        </Form.Group>
        <Form.Group controlId="password" className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={loading} // Disable input when loading
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="w-100" disabled={loading}>
          {/* Show a spinner in the button if loading */}
          {loading ? (
            <>
              <Spinner animation="border" size="sm" /> Logging in...
            </>
          ) : (
            'Login'
          )}
        </Button>
      </Form>

      {/* Overlay with Spinner */}
      {loading && (
        <div className="overlay">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </Container>
  );
};

export default Login;
