import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Login from './Login';
import PurchaseForm from './PurchaseForm';
import { Container, Button, Row, Col, Alert } from 'react-bootstrap';

const Main = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState(''); // State for storing username

  // Use environment variable for API URL
  const API_URL = process.env.REACT_APP_API_URL;

  // Handle login success and update the state
  const handleLoginSuccess = (user) => {
    setIsLoggedIn(true);
    setUsername(user.username); // Set the username upon successful login
  };

  // Check if the user is already authenticated on component mount
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        // Call the /isAuth endpoint to check if the user is already logged in
        const response = await axios.get(`${API_URL}/isAuth`, { withCredentials: true });
        const user = response.data.isAuthenticated && response?.data?.user;
        if (user) {
          handleLoginSuccess(user); // If authenticated, trigger login success
        }
      } catch (err) {
        console.log('User is not authenticated, please log in.', err.message);
      }
    };

    checkAuthStatus(); // Invoke the checkAuthStatus function inside useEffect
  }, [API_URL]); // Empty dependency array means this runs only on component mount


  // Handle logout by calling the server's /logout endpoint
  const handleLogout = async () => {
    try {
      await axios.post(`${API_URL}/logout`, {}, { withCredentials: true });
      setIsLoggedIn(false);
      setUsername(''); // Clear the username state on logout
    } catch (err) {
      console.error('Logout failed:', err);
    }
  };

  return (
    <Container className="mt-5 container-sm mw-sm">
      {isLoggedIn ? (
        <>
          <Alert variant="secondary">
            <Row className="align-items-center">
              {/* Column for the text, aligned to the left */}
              <Col className="text-left">
                {/* Display the logged-in username */}
                Logged in as: <strong>{username}</strong>
              </Col>
              {/* Column for the button, aligned to the right */}
              <Col className="text-end">
                <Button className="btn-sm" variant="danger" onClick={handleLogout}>
                  Logout
                </Button>
              </Col>
            </Row>
          </Alert>
          <PurchaseForm /> {/* Show the form after login */}
        </>
      ) : (
        <Login onLoginSuccess={handleLoginSuccess} />
      )}
    </Container>
  );
};

export default Main;
