// src/App.js

import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './Main'; // Import Main component
import PartnerLogin from './PartnerLogin'; // Import PartnerLogin component

function App() {
  return (
    <Router>
      <div className="App">
        {/* Use Routes instead of Switch */}
        <Routes>
          {/* Replace component prop with element prop and wrap the component in JSX */}
          <Route path="/partner-login" element={<PartnerLogin />} />
          <Route path="/" element={<Main />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
